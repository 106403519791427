import VueCompositionApi from '@vue/composition-api';

if (window.location.hash !== '') {
  const target = document.querySelector(window.location.hash) as HTMLElement;

  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
  } else {
    window.scrollTo(0, target.offsetTop);
  }
}

// App main
const main = async () => {
  const { default: svg4everybody } = await import(
    /* webpackMode: "lazy", webpackChunkName: "svg4everybody" */ 'svg4everybody'
  );
  // Async load the vue module
  const { default: Vue } = await import(
    /* webpackMode: "lazy", webpackChunkName: "vue" */ 'vue'
  );
  const { default: VueMq } = await import(
    /* webpackMode: "lazy", webpackChunkName: "mq" */ 'vue-mq'
  );
  const { default: CookieLaw } = await import(
    /* webpackMode: "lazy", webpackChunkName: "cookie-law" */ 'vue-cookie-law'
  );
  const { default: CookieComponent } = await import(
    /* webpackMode: "lazy", webpackChunkName: "cookie-component" */ './components/CookieComponent.vue'
  );
  const { default: lazyLoadComponent } = await import(
    /* webpackMode: "lazy", webpackChunkName: "lazy-load-component" */ '../utils/lazy-load-component'
  );

  svg4everybody();

  Vue.config.productionTip = false;

  Vue.use(VueCompositionApi);
  Vue.use(VueMq, {
    breakpoints: {
      sm: 768,
      md: 1024,
      lg: Infinity,
    },
  });
  Vue.component('cookie-law', CookieLaw as any);
  Vue.component('cookie-component', CookieComponent);

  // Create our vue instance
  const vm = new Vue({
    el: '#page-container',
  });

  return vm;
};

// Execute async function
main().then(vm => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
